import * as ReservationContainerActions from './actions/reservation-container.actions';
import * as ReservationEffectsActions from './actions/reservation-effects.actions';
import { reservationFeature } from './reservation.reducers';

export * as reservationState from './reservation.state';

const { reducer, name, ...reservationSelectors } = reservationFeature;

export {
  reducer as reservationReducer,
  name as reservationName,
  reservationSelectors,
  ReservationContainerActions,
  ReservationEffectsActions,
};

import { createAction, props } from '@ngrx/store';
import { List } from 'src/app/core/models/app.models';

export const formValid = createAction('[Reservation Effects] Form Valid');
export const formInvalid = createAction('[Reservation Effects] Form Invalid');
export const assignReservationSuccessful = createAction(
  '[Reservation Effects] Reservation assigned Successful',
);
export const assignReservationUnsuccessful = createAction(
  '[Reservation Effects] Reservation assigned Unsuccessful',
);
export const cancelReservationSuccessful = createAction(
  '[Reservation Effects] Cancel Reservation Successful',
);
export const cancelReservationUnsuccessful = createAction(
  '[ReservationEffects] Cancel Reservation Failed',
);

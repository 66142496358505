import { createAction, props } from '@ngrx/store';
import { Event, Reservation } from 'src/app/core/models/app.models';

export const assignReservationClicked = createAction(
  '[Reservation Container] Assign Reservation Clicked',
  props<{
    reservation: Reservation;
  }>(),
);

export const cancelReservationClicked = createAction(
  '[Reservation Container] Cancel Reservation Clicked',
  props<{
    reservation: Reservation;
  }>(),
);

export const backClicked = createAction(
  '[Reservation Container] Back Clicked',
  props<{
    event: Event;
  }>(),
);

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Reservation } from '../models/app.models';

export interface AssignReservation {
  readonly reservationId: number;
  readonly internComment: string;
  readonly message: string;
  readonly loungeId: number;
}

@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  constructor(private http: HttpClient) {}

  getAllReservationRequestsFromEvent(
    eventId: number | null,
  ): Observable<Reservation[]> {
    return this.http.get<Reservation[]>(
      environment.api + '/reservation/request/all/' + eventId,
    );
  }

  getReservationById(id: number | null): Observable<Reservation> {
    return this.http.get<Reservation>(environment.api + '/reservation/' + id);
  }

  createReservation(reservation: Reservation): Observable<any> {
    return this.http.post(environment.api + '/reservation', reservation).pipe(
      map((response: any) => response.id),
      catchError((error: HttpErrorResponse) =>
        throwError(
          () =>
            new Error(
              `Something bad happened; please try again later. Http-Response: ${error.status}`,
            ),
        ),
      ),
    );
  }

  assignReservation(reservation: AssignReservation) {
    return this.http.put(environment.api + '/reservation/assign', reservation);
  }

  cancelReservation(reservationId: number) {
    return this.http
      .delete(environment.api + `/reservation/${reservationId}`)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              new Error(
                `Something bad happened; please try again later. Http-Response: ${error.status}`,
              ),
          ),
        ),
      );
  }

  updateReservation(reservation: Reservation): Observable<any> {
    return this.http.put(environment.api + '/reservation', reservation).pipe(
      map((response: any) => response.id),
      catchError((error: HttpErrorResponse) =>
        throwError(
          () =>
            new Error(
              `Something bad happened; please try again later. Http-Response: ${error.status}`,
            ),
        ),
      ),
    );
  }
}

import { createFeature, createReducer } from '@ngrx/store';
import { addDays, format } from 'date-fns';
import { ReservationState } from './reservation.state';

export const initialState: Readonly<ReservationState> = {
  reservation: {
    id: 0,
    name: '',
    email: '',
    telephone: '',
    numberPersons: 0,
    arrival: '',
    comment: '',
    internComment: '',
    message: '',
    lounge: null,
    event: null,
    reservationStatus: 'assigned',
  },
  eventInformation: {
    id: 0,
    name: '',
    ageRestrictionWomen: '',
    ageRestrictionMen: '',
    ticketLink: '',
    startDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
    endDate: '',
    startTime: '',
    endTime: '',
    fromPrice: '',
    pressText: '',
    internalLabel: -1,
    fyraTicketLink: '',
    location: null,
    locationName: '',
    locationStreet: '',
    locationPlz: '',
    locationProvince: '',
    genres: [],
    tags: [],
    flyer: null,
  },
};

export const reservationReducers = createReducer(initialState);

export const reservationFeature = createFeature({
  name: 'reservation',
  reducer: reservationReducers,
});
